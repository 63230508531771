import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/services/subscription/subscription-service'
import { type PartialDimensionnedState, productSelectionDimensions, quoteDimension } from '@orus.eu/dimensions'
import { useState } from 'react'

export function useRequiredDimensionsHighlights({
  state,
  cleanTabsMissingDimensions,
  nonDimensionalDataAfter,
}: {
  state: PartialDimensionnedState<typeof productSelectionDimensions> & PartialDimensionnedState<[typeof quoteDimension]>
  subscriptionId: string
  cleanTabsMissingDimensions: () => void
  nonDimensionalDataAfter?: SubscriptionNonDimensionalData
}): {
  toggleHighlightDimensions: () => void
  isHighlightDimensionsActive: boolean
  highlightDimensionsNames: string[]
  requiredDimensionsNames: string[]
} {
  const {
    requiredQuoteDimensionsNames,
    requiredSubscriptionDimensionsNames,
    missingQuoteDimensionsNames,
    missingSubscriptionDimensionsNames,
  } = nonDimensionalDataAfter || {}

  const [isHighlightDimensionsActive, setIsHighlightDimensionsActive] = useState<boolean>(false)

  const toggleHighlightDimensions = () => {
    cleanTabsMissingDimensions()
    setIsHighlightDimensionsActive((previous) => !previous)
  }

  const missingDimensions = state.quote ? missingSubscriptionDimensionsNames : missingQuoteDimensionsNames
  const highlightDimensionsNames = isHighlightDimensionsActive ? (missingDimensions ?? []) : []

  const requiredDimensionsNames =
    (state.quote ? requiredSubscriptionDimensionsNames : requiredQuoteDimensionsNames) || []

  return {
    toggleHighlightDimensions,
    highlightDimensionsNames,
    isHighlightDimensionsActive,
    requiredDimensionsNames,
  }
}
